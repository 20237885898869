import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import keccak256 from "keccak256";
import MerkleTree from "merkletreejs";
import etherIcon from './fonts/etherscan.svg';
import twitterIcon from './fonts/twitter.svg';
import openseaIcon from './fonts/opensea.svg';


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-family: "Lato", serif;
  color: white;
  font-size: 22px;
  background-color: #3A98FF;
  border-radius: 5px;
  border: none;
  transition: background-color .4s;
  padding: 15px 55px 15px 55px;
  min-width: 260px;
  :hover {
    background-color: #5EA5F3;
    transition: background-color .4s;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 20px 40px;
  border-radius: 100%;
  border: none;
  background-color: #2E5F8C;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  position: relative;
  width: 200px;
  top: -25px;
  transition: width 0.5s;
  transition: height 0.5s;
  @media only screen and (max-width:625px) {
    width: 148px!important;
  }
`;

export const StyledImg = styled.img`
  width: 130vw;
  min-width: 2000px;
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    MAX_MINT_AMOUNT: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    WHITELIST: false,
    WHITELIST_ADDRESSES: [],
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    console.log("Cost: ", totalCostWei);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    if (!CONFIG.WHITELIST) {
      blockchain.smartContract.methods
        .mint(mintAmount)
        .estimateGas({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .then((estimatedGas) =>
          blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
              gasLimit: String(estimatedGas),
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
              value: totalCostWei,
            })
            .once("error", (err) => {
              console.log(err);
              setFeedback(
                "Sorry, something went wrong please try again."
              );
              setClaimingNft(false);
            })
            .then((receipt) => {
              console.log(receipt);
              setFeedback(
                `Your transaction was successful. Go to OpenSea.io to view your NFTs`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            })
        )
        .catch((e) => {
          console.log(e);
          setFeedback("Sorry, something went wrong please try again.");
        });
    } else {
      const whitelist = CONFIG.WHITELIST_ADDRESSES;
      const buf2hex = (x) => "0x" + x.toString("hex");
      const leaves = whitelist.map((x) => keccak256(x));
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });

      let leaf = buf2hex(keccak256(blockchain.account));
      let proof = tree.getProof(leaf).map((x) => buf2hex(x.data));

      blockchain.smartContract.methods
        .whitelistMint(mintAmount, proof)
        .estimateGas({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .then((estimatedGas) =>
          blockchain.smartContract.methods
            .whitelistMint(mintAmount, proof)
            .send({
              gasLimit: String(estimatedGas),
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
              value: totalCostWei,
            })
            .once("error", (err) => {
              console.log(err);
              setFeedback(
                "Sorry, your wallet address is not approved."
              );
              setClaimingNft(false);
            })
            .then((receipt) => {
              console.log(receipt);
              setFeedback(
                `Your transaction was successful. Go to OpenSea.io to view your NFTs`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            })
        )
        .catch((e) => {
          console.log(e);
          setFeedback("Sorry, something went wrong please try again later.");
        });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > CONFIG.MAX_MINT_AMOUNT) {
      newMintAmount = CONFIG.MAX_MINT_AMOUNT;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, background: "linear-gradient(#144E80, #17356B)"}}
      >

            <s.Container jc={"center"} ai={"center"} style={{backgroundColor: "#134F80", height: "69px", width:"100vw", position: "fixed", top: 0, paddingTop: "50px", paddingLeft: "10%", paddingRight: "10%", borderBottom: "1px solid #FFFFFF29", zIndex: "1000"}}> 
              <ResponsiveWrapper flex={6} style={{ padding: 2, marginLeft: "-9.5%" }} test>
                <StyledLogo alt={"logo"} src={"/config/images/logo.png"}/>
              </ResponsiveWrapper>
              <ResponsiveWrapper flex={6} style={{ position: "fixed", top: 23, right: "-83%", fontSize: "18px" }} test>
                <StyledLink style={{fontFamily: "Lato",color: "rgba(255, 255, 255, 0.8)", fontWeight: "bold"}} href={"https://moonapes.org"}>
                  Home
                </StyledLink>  
              </ResponsiveWrapper>
            </s.Container>
          
      
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={6} jc={"center"} ai={"center"} style={{  padding: 12}}>
              <s.SpacerLarge />
            <s.TextTitle style={{textAlign: "center",lineHeight: "1em",fontSize: 45,fontWeight: "bold",color: "#D7EBD1",fontFamily: "gazpacho", width: "90vw"}}>
              Created to provide value to holders
            </s.TextTitle>
            <s.SpacerSmall />
            <s.TextTitle style={{textAlign: "center",lineHeight: "1em",fontSize: 30,color: "#fff",fontFamily: "Lato",width: "100%"}}>
              A collection of 10,000 Moonapes
            </s.TextTitle>
            <s.SpacerLarge/>
            <StyledImg alt={"example"}src={"/config/images/group-3.png"}/>
          <s.Container style={{ }}>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle style={{textAlign: "left",color: "var(--secondary-text)",}}>
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription style={{textAlign: "left",color: "var(--secondary-text)",}}>
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                   
                    <s.SpacerMedium />
                    <StyledButton onClick={(e) => {e.preventDefault(); dispatch(connect()); getData();}}>
                      Connect Wallet
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{textAlign: "center",color: "#fff",}}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton style={{ lineHeight: 0.4, fontFamily: "Lato" }}disabled={claimingNft ? 1 : 0}onClick={(e) => {e.preventDefault();decrementMintAmount();}}>
                        -
                      </StyledRoundButton>
                      <s.SpacerSmall />
                      <s.TextDescription style={{textAlign: "center",color: "#fff",fontFamily: "Lato",}}>
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledRoundButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); incrementMintAmount(); }}>
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                   
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); claimNFTs(); getData(); }}>
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                    <s.TextDescription style={{textAlign: "center",color: "#fff",fontFamily: "Lato",fontSize: "10px",width: "100%"}}>
                      {feedback}
                    </s.TextDescription>
                    <s.TextTitle style={{textAlign: "center",fontSize: 22,color: "#fff",fontWeight: "bolder",fontFamily: "Lato",width: "100%"}}>
                      {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                    </s.TextTitle>
                  </>
                )}
              </>
            )}
            </s.Container> {/* added container */}
          
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          {/* ADD NEW CONTAINER HERE FOR 2ND COLUMN*/}
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription style={{textAlign: "center",fontFamily: "Lato",fontSize: "12px",color: "rgba(255, 255, 255, 0.8)",}}>
            <StyledLink target={"_blank"} style={{color: "rgba(255, 255, 255, 0.8)"}} href={CONFIG.SCAN_LINK}>
            <img src={etherIcon} style={{ width: "24px", marginLeft: "10px", marginRight: "10px"}}/>
            </StyledLink>  
            <StyledLink target={"_blank"} style={{color: "rgba(255, 255, 255, 0.8)"}} href={CONFIG.MARKETPLACE_LINK}>
            <img src={openseaIcon} style={{ width: "25px", marginLeft: "10px", marginRight: "10px"}}/>
            </StyledLink> 
            <StyledLink target={"_blank"} style={{color: "rgba(255, 255, 255, 0.8)"}} href={CONFIG.TWITTER_LINK}>
            <img src={twitterIcon} style={{ width: "25px", marginLeft: "10px", marginRight: "10px"}}/>
            </StyledLink>  
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
